/* eslint-disable */
import axios from 'axios';
import { API_URL } from '@/common/config';

export async function redireccion() {
  try {
    const token = localStorage.getItem('token');

    if (token !== null && token !== '') {
      const timeout = 3000; // 3 segundos
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), timeout);

      const response = await axios.post(
        `${API_URL}api/v1/auth/validarToken`,
        {},
        {
          headers: {
            token: token,
          },
        },
      );
      clearTimeout(timeoutId);
      // Verificar si la respuesta es OK (status 200)
      if (response.data.status === 'success') {
        return true; // Token válido
      }
    }
    // Si no hay token o el token no es válido, redirige al login
    localStorage.removeItem('token');
    localStorage.clear();
    return false;
  } catch (error) {
    console.error('Error en redireccion:', error);
    // Manejar errores específicos, por ejemplo:
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      localStorage.clear();
    }
    return false; // Regresa false en caso de error
  }
}
